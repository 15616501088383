@import '../variables.scss';

.section--hero {
  font-size: 20px;
  margin: 0 auto;
  overflow: hidden;
  padding: 50px 0 100px;
  position: relative;
  text-align: center;
}

.container--hero {
  margin: 0 auto;
  padding: 0;
  max-width: $screen-sm-min - 200px;
}

.hero__text {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.earth--day .section--hero {
  background-color: $palecerulean-light;
  color: $queenblue-dark;
}

.space--day .section--hero {
  background-color: $independence-lightest;
  color: $queenblue-darker;
}

.earth--night .section--hero {
  background-color: $queenblue-dark;
  box-shadow: inset 0 300px 300px -300px rgba(9, 13, 19, 1);
  color: $maize;
}

.space--night .section--hero {
  background-color: $independence-dark;
  box-shadow: inset 0 300px 300px -300px rgba(9, 13, 19, 1);
  color: $maize;
}

.space--day .hero__image--earth,
.space--night .hero__image--earth,
.earth--day .hero__image--saturn-smooth,
.earth--night .hero__image--saturn-smooth {
  visibility: hidden;
}

.hero__image--earth,
.hero__image--saturn-smooth {
  bottom: -100px;
  height: 200px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
}

.hero__image--earth:hover,
.hero__image--saturn-smooth:hover {
  cursor: pointer;
}

.earthspin {
  animation: earth-earthspin 5s 1 ease-out;
}

@keyframes earth-earthspin {
  0% {
    transform: rotate(0deg);
    transform-origin: center;
  }
  50% {
    transform: rotate(180deg);
    transform-origin: center;
  }
  100% {
    transform: rotate(-360deg);
    transform-origin: center;
  }
}

.space--day .hero__image--rocket,
.space--night .hero__image--rocket,
.earth--day .hero__image--rocket-alien,
.earth--night .hero__image--rocket-alien {
  visibility: hidden;
}

.hero__image--rocket {
  animation: rocket-wiggle 10s infinite ease-out;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 5%;
  top: 90px;
  width: 47px;
}

.hero__image--rocket-alien {
  animation: rocket-wiggle 10s infinite ease-out;
  height: 35px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 1%;
  top: 125px;
  transition: transform 2s ease-in;
  width: 90px;
}

@keyframes rocket-wiggle {
  0% {
    transform: scale(1);
  }
  10% {
    transform: rotate(0deg) scale(1);
    transform-origin: center;
  }
  12% {
    transform: rotate(1deg) scale(1.1);
    transform-origin: center;
  }
  14% {
    transform: rotate(-2deg) scale(1);
    transform-origin: center;
  }
  16% {
    transform: rotate(1deg) scale(1.1);
    transform-origin: center;
  }
  17% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.hero__image--rocket:hover,
.hero__image--rocket:focus,
.hero__image--rocket-alien:hover,
.hero__image--rocket-alien:focus {
  animation: rocket-hover 0.5s infinite ease-out;
  cursor: pointer;
  outline: none;
}

@keyframes rocket-hover {
  0% {
    transform: rotate(0deg);
    transform-origin: center;
  }
  25% {
    transform: rotate(1deg);
    transform-origin: center;
  }
  50% {
    transform: rotate(-2deg);
    transform-origin: center;
  }
  75% {
    transform: rotate(1deg);
    transform-origin: center;
  }
  100% {
    transform: rotate(0deg);
    transform-origin: center;
  }
}

.hero__image--rocket.takeoff {
  animation: rocket-takeoff 4s 1 ease-out;
}

.hero__image--rocket-alien.takeoff {
  animation: rocket-land 4s 1 ease-out;
}

@keyframes rocket-takeoff {
  0% {
    transform: rotate(0deg);
    transform-origin: center;
  }
  2% {
    transform: rotate(1deg);
    transform-origin: center;
  }
  4% {
    transform: rotate(-2deg);
    transform-origin: center;
  }
  6% {
    transform: rotate(1deg);
    transform-origin: center;
  }
  8% {
    transform: translate(0, 0) scale(1);
  }
  100% {
    transform: translate(50px, -200px) scale(0.3);
  }
}

@keyframes rocket-land {
  0% {
    transform: rotate(0deg);
    transform-origin: center;
  }
  2% {
    transform: rotate(1deg);
    transform-origin: center;
  }
  4% {
    transform: rotate(-2deg);
    transform-origin: center;
  }
  6% {
    transform: rotate(1deg);
    transform-origin: center;
  }
  15% {
    transform: rotate(70deg) scale(1);
    transform-origin: center;
  }
  100% {
    transform: rotate(70deg) translate(-300px, -100px) scale(0.3);
    transform-origin: center;
  }
}

.earth--day .hero__image--moon,
.earth--day .hero__image--earth-minimal,
.earth--day .hero__image--jupiter,
.earth--night .hero__image--sun,
.earth--night .hero__image--earth-minimal,
.earth--night .hero__image--jupiter,
.space--day .hero__image--moon,
.space--day .hero__image--sun,
.space--day .hero__image--jupiter,
.space--night .hero__image--sun,
.space--night .hero__image--moon,
.space--night .hero__image--earth-minimal {
  visibility: hidden;
}

.hero__image--moon,
.hero__image--sun,
.hero__image--jupiter {
  animation: sunmoon-wiggle 8s infinite ease-out;
  height: 60px;
  left: 2%;
  position: absolute;
  top: 70px;
  transition: transform 2s ease-in;
  width: 60px;
}

.hero__image--earth-minimal {
  animation: sunmoon-wiggle 8s infinite ease-out;
  height: 55px;
  left: 2%;
  position: absolute;
  top: 70px;
  transition: transform 2s ease-in;
  width: 55px;
}

@keyframes sunmoon-wiggle {
  0% {
    transform: scale(1);
  }
  8% {
    transform: rotate(0deg) scale(1);
    transform-origin: center;
  }
  10% {
    transform: rotate(3deg) scale(1.1);
    transform-origin: center;
  }
  12% {
    transform: rotate(-6deg) scale(1);
    transform-origin: center;
  }
  14% {
    transform: rotate(3deg) scale(1.1);
    transform-origin: center;
  }
  15% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.hero__image--moon:hover,
.hero__image--moon:focus,
.hero__image--sun:hover,
.hero__image--sun:focus,
.hero__image--earth-minimal:hover,
.hero__image--earth-minimal:focus,
.hero__image--jupiter:hover,
.hero__image--jupiter:focus {
  animation: sunmoon-hover 1s infinite ease-out;
  cursor: pointer;
  outline: none;
}

@keyframes sunmoon-hover {
  0% {
    transform: scale(1);
    transform-origin: center;
  }
  25% {
    transform: scale(1.1) translate(0, -1px);
    transform-origin: center;
  }
  50% {
    transform: scale(1) translate(0, 1px);
    transform-origin: center;
  }
  75% {
    transform: scale(1.1) translate(0, -1px);
    transform-origin: center;
  }
  100% {
    transform: scale(1);
  }
}

/* Media Sizes */

@media (min-width: $screen-sm-min) {
  .section--hero {
    font-size: 35px;
    padding: 10px 0 100px;
  }

  .container--hero {
    max-width: $screen-sm-min;
  }

  .hero__image--earth {
    bottom: -110px;
    height: 220px;
  }

  .hero__image--rocket {
    height: 125px;
    right: 100px;
    top: 50px;
    width: 58px;
  }

  .hero__image--rocket-alien {
    height: 60px;
    right: 40px;
    top: 70px;
    width: 130px;
  }

  .hero__image--moon,
  .hero__image--sun,
  .hero__image--jupiter {
    height: 90px;
    left: 80px;
    top: 50px;
    width: 90px;
  }

  .hero__image--earth-minimal {
    height: 85px;
    left: 80px;
    top: 50px;
    width: 85px;
  }
}

/* Desktop Screen Size */

@media (min-width: $screen-md-min) {
  .section--hero {
    font-size: 50px;
  }

  .container--hero {
    max-width: $screen-md-min - 50px;
  }

  .hero__image--rocket {
    height: 150px;
    right: 100px;
    top: 50px;
    width: 70px;
  }

  .hero__image--rocket-alien {
    height: 80px;
    right: 30px;
    top: 80px;
    width: 172px;
  }

  .hero__image--moon,
  .hero__image--sun,
  .hero__image--jupiter {
    height: 100px;
    left: 100px;
    top: 50px;
    width: 100px;
  }

  .hero__image--earth-minimal {
    height: 95px;
    left: 100px;
    top: 50px;
    width: 95px;
  }
}

/* XL Desktop Screen Size */

@media (min-width: $screen-lg-min) {
  .container--hero {
    max-width: $screen-md-min;
  }

  .hero__image--rocket {
    right: 15%;
  }

  .hero__image--rocket-alien {
    right: 8%;
  }

  .hero__image--moon,
  .hero__image--sun,
  .hero__image--earth-minimal,
  .hero__image--jupiter {
    left: 15%;
  }
}
