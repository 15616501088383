@import '../../../variables.scss';

.sunflower__petal-group {
  visibility: hidden;
}

@keyframes sunflower-rotation {
  0% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
    transform-box: fill-box;
  }
  100% {
    transform: rotate(360deg);
    transform-origin: 50% 50%;
    transform-box: fill-box;
  }
}

.welcome__image--sunflower-petals {
  animation: sunflower-rotation 40s infinite linear;
}

.earth--day {
  .sunflower__leaf--accent,
  .sunflower__leaf--main,
  .sunflower__petal--accent,
  .sunflower__petal--main,
  .sunflower__center--main,
  .welcome__image--sunflower-petals {
    fill: $pistachio;
  }
}

.earth--night {
  .sunflower__leaf--accent,
  .sunflower__leaf--main,
  .sunflower__petal--accent,
  .sunflower__petal--main,
  .sunflower__center--main,
  .welcome__image--sunflower-petals {
    fill: $pistachio-dark;
  }
}
