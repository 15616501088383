@import '../../../variables.scss';

.earth--day {
  .rocket__fire--accent {
    fill: #f9a369;
  }

  .rocket__fire--main {
    fill: #f7904b;
  }

  .rocket__body {
    fill: $palecerulean-darker;
  }

  .rocket__window {
    fill: $palecerulean-lighter;
  }
}

.earth--night {
  .rocket__fire--accent {
    fill: #f9a369;
  }

  .rocket__fire--main {
    fill: #f7904b;
  }

  .rocket__body {
    fill: $palecerulean-darker;
  }

  .rocket__window {
    fill: #c1e5e5;
  }
}

.rocket__fire--accent {
  animation: fire-accent 4s infinite linear;
}

@keyframes fire-accent {
  0% {
    fill: #f9a369;
  }
  50% {
    fill: $maize-dark;
  }
  100% {
    fill: #f9a369;
  }
}
