@import '../variables';

.mountain__st0 {
  stroke: $blackgrey;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.earth--day .mountain__st0,
.space--day .mountain__st0 {
  fill: $independence;
}

.earth--night .mountain__st0,
.space--night .mountain__st0 {
  fill: $independence-dark;
}

.mountain__st1 {
  fill: $clean;
}

.earth--day .mountain__st2,
.space--day .mountain__st2 {
  fill: $independence;
}

.earth--night .mountain__st2,
.space--night .mountain__st2 {
  fill: $independence-dark;
}

.mountain__st3 {
  fill: none;
  stroke: $blackgrey;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
