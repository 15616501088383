@import '../variables.scss';

.section--welcome {
  color: $blackgrey;
  padding: 75px 20px;
  position: relative;
}

.container--welcome {
  margin: 0 auto;
  padding: 0;
  max-width: $screen-sm-min - 200px;
}

.earth--day .section--welcome {
  background-color: $queenblue;
  color: $maize;
}

.earth--night .section--welcome {
  background-color: $palecerulean;
  color: $blackgrey;
}

.space--day .section--welcome {
  background-color: $queenblue;
  color: $maize;
}

.space--night .section--welcome {
  background-color: $independence-lightest;
  color: $blackgrey;
}

.headshot {
  border: 5px solid $palecerulean-light;
  border-radius: 50%;
  height: 120px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  top: -60px;
}

.earth--day .headshot {
  border-color: $queenblue;
}

.earth--night .headshot {
  border-color: $palecerulean;
}

.space--day .headshot {
  border-color: $queenblue;
}

.space--night .headshot {
  border-color: $independence-lightest;
}

.welcome__image--vine-1 {
  bottom: 0;
  height: 350px;
  left: 1%;
  position: absolute;
  visibility: hidden;
  width: 85px;
}

.welcome__image--vine-2 {
  bottom: 0;
  height: 350px;
  position: absolute;
  right: 1%;
  visibility: hidden;
  width: 85px;
}

.welcome__image--tulips-1,
.welcome__image--alien-1 {
  bottom: 0;
  height: 75px;
  left: 8%;
  position: absolute;
  width: 75px;
}

.welcome__image--tulips-2,
.welcome__image--alien-2 {
  bottom: 0;
  height: 75px;
  left: 50%;
  position: absolute;
  visibility: hidden;
  width: 75px;
}

.welcome__image--tulips-3,
.welcome__image--alien-3 {
  bottom: 0;
  height: 75px;
  position: absolute;
  right: 12%;
  width: 75px;
}

.welcome__image--tulips-1,
.welcome__image--tulips-2,
.welcome__image--tulips-3 {
  animation: tulip-scale 10s infinite linear;
}

@keyframes tulip-scale {
  0% {
    transform: scale(1, 1);
    transform-origin: bottom;
    transform-box: fill-box;
  }
  50% {
    transform: scale(1, 1.15);
    transform-origin: bottom;
    transform-box: fill-box;
  }
  100% {
    transform: scale(1, 1);
    transform-origin: bottom;
    transform-box: fill-box;
  }
}

.welcome__image--sunflower-1 {
  bottom: 0;
  height: 50px;
  position: absolute;
  right: 30%;
  transform-box: fill-box;
  visibility: hidden;
  width: 35px;
}

.welcome__image--sunflower-petals-1 {
  bottom: 15px;
  height: 35px;
  position: absolute;
  right: 30%;
  transform-box: fill-box;
  visibility: hidden;
  width: 35px;
}

.welcome__image--sunflower-2 {
  bottom: 0;
  height: 50px;
  left: 40%;
  position: absolute;
  transform-box: fill-box;
  width: 35px;
}

.welcome__image--sunflower-petals-2 {
  bottom: 15px;
  height: 35px;
  left: 40%;
  position: absolute;
  transform-box: fill-box;
  width: 35px;
}

.space--day .welcome__image--tulips-1,
.space--day .welcome__image--tulips-2,
.space--day .welcome__image--tulips-3,
.space--day .welcome__image--sunflower-1,
.space--day .welcome__image--sunflower-2,
.space--day .welcome__image--sunflower-petals-1,
.space--day .welcome__image--sunflower-petals-2,
.space--night .welcome__image--tulips-1,
.space--night .welcome__image--tulips-2,
.space--night .welcome__image--tulips-3,
.space--night .welcome__image--sunflower-1,
.space--night .welcome__image--sunflower-2,
.space--night .welcome__image--sunflower-petals-1,
.space--night .welcome__image--sunflower-petals-2,
.earth--day .welcome__image--alien-1,
.earth--day .welcome__image--alien-2,
.earth--day .welcome__image--alien-3,
.earth--night .welcome__image--alien-1,
.earth--night .welcome__image--alien-2,
.earth--night .welcome__image--alien-3 {
  visibility: hidden;
}

/* Media Sizes */

@media (min-width: $screen-sm-min) {
  .container--welcome {
    max-width: $screen-sm-min - 200px;
  }

  .section--welcome {
    padding: 75px 80px;
  }

  .welcome__image--tulips-1,
  .welcome__image--alien-1 {
    left: 15%;
  }

  .welcome__image--tulips-3,
  .welcome__image--alien-3 {
    right: 15%;
  }

  .welcome__image--sunflower-1,
  .welcome__image--sunflower-petals-1 {
    right: 35%;
    visibility: visible;
  }

  .welcome__image--sunflower-2,
  .welcome__image--sunflower-petals-2 {
    left: 35%;
  }

  .welcome__image--vine-1 {
    visibility: visible;
  }

  .welcome__image--vine-2 {
    visibility: visible;
  }

  .space--day .welcome__image--tulips-1,
  .space--day .welcome__image--tulips-2,
  .space--day .welcome__image--tulips-3,
  .space--day .welcome__image--sunflower-1,
  .space--day .welcome__image--sunflower-2,
  .space--night .welcome__image--tulips-1,
  .space--night .welcome__image--tulips-2,
  .space--night .welcome__image--tulips-3,
  .space--night .welcome__image--sunflower-1,
  .space--night .welcome__image--sunflower-2,
  .earth--day .welcome__image--alien-1,
  .earth--day .welcome__image--alien-2,
  .earth--day .welcome__image--alien-3,
  .earth--night .welcome__image--alien-1,
  .earth--night .welcome__image--alien-2,
  .earth--night .welcome__image--alien-3 {
    visibility: hidden;
  }
}

/* Desktop Screen Size */

@media (min-width: $screen-md-min) {
  .container--welcome {
    max-width: $screen-md-min - 200px;
  }

  .welcome__image--tulips-2,
  .welcome__image--alien-2 {
    visibility: visible;
  }

  .welcome--text {
    font-size: 18px;
    padding-top: 20px;
  }
}

/* XL Desktop Screen Size */

@media (min-width: $screen-lg-min) {
  .container--welcome {
    max-width: $screen-md-min - 200px;
  }

  .welcome__image--vine-1 {
    left: 4%;
  }

  .welcome__image--vine-2 {
    right: 4%;
  }
}
