@import '../../../variables.scss';

.sun--light {
  fill: $maize-light;
}

.sun--medium {
  fill: $maize-dark;
}

.sun--dark {
  fill: $maize-darker;
}
