@import '../../../variables.scss';

.jupiter--medium {
  fill: $palecerulean-dark;
}

.jupiter--light {
  fill: $pistachio-light;
}

.jupiter--dark {
  fill: $queenblue;
}
