@import '../variables.scss';

footer {
  color: $clean;
  margin: 0 auto;
}

.container--footer {
  margin: 0 auto;
  padding: 0;
  max-width: $screen-sm-min - 50px;
}

.earth--day footer {
  background-color: $independence-darker;
}

.earth--night footer {
  background-color: $independence-darkest;
}

.space--day footer {
  background-color: $independence-darker;
}

.space--night footer {
  background-color: $independence-darkest;
}

.list--footer-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 10px 0;
}

.listItem--footer-nav {
  flex-grow: 1;
  text-align: center;
}

.listItem__text--footer-nav {
  align-items: center;
  color: $clean;
  display: flex;
  height: 41px;
  justify-content: center;
}

.listItem__text--footer-nav:hover,
.listItem__text--footer-nav:focus {
  color: $maize;
  cursor: pointer;
  outline: none;
}

.listItem__underline--footer-nav {
  border-bottom: solid transparent 2px;
}

.listItem__text--footer-nav:hover .listItem__underline--footer-nav,
.listItem__text--footer-nav:focus .listItem__underline--footer-nav {
  border-bottom: solid $maize 2px;
}

.footer__uparrow {
  height: 19px;
  padding-bottom: 2px;
  padding-right: 10px;
  width: 22px;
}

.arrow {
  fill: $clean;
}

.listItem__text--footer-nav:hover .arrow,
.listItem__text--footer-nav:focus .arrow {
  fill: $maize;
}

.footer__copyright {
  display: block;
  margin: 0 auto;
  padding: 5px;
  text-align: center;
}

/* Media Sizes */

@media (min-width: $screen-sm-min) {
  .container--footer {
    max-width: $screen-sm-min - 50px;
  }

  .list--footer-nav {
    flex-direction: row;
  }
}

/* Desktop Screen Size */

@media (min-width: $screen-md-min) {
  .container--footer {
    max-width: $screen-md-min - 50px;
  }
}

/* XL Desktop Screen Size */

@media (min-width: $screen-lg-min) {
  .container--footer {
    max-width: $screen-md-min;
  }
}
