@import '../variables.scss';

* {
  box-sizing: border-box;
}

html {
  background-color: $palecerulean-darkest;
  overflow-x: hidden;
  width: 100%;
}

body {
  font-family: 'Rubik', sans-serif;
  margin: 0 auto;
  overflow-x: hidden;
  width: 100%;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

section {
  margin: 0 auto;
  padding: 10px;
}

.section__header {
  text-align: center;
}

/* Desktop Screen Size */

@media (min-width: $screen-md-min) {
  .section__header {
    font-size: 25px;
  }
}
