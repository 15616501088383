/*
 * Colors
 * ========================================================================== */

$maize: #f7ef6a;
$maize-light: #f8f17d;
$maize-lighter: #faf5a2;
$maize-dark: #f3e720;
$maize-darker: #dfd30c;
$maize-transparent: rgba(247, 239, 106, 0.5);

$pistachio: #93c178;
$pistachio-light: #a9cd93;
$pistachio-lighter: #bedaae;
$pistachio-dark: #7db55d;
$pistachio-darker: #619443;

$queenblue: #465c8b;
$queenblue-light: #4d6598;
$queenblue-lighter: #798ebb;
$queenblue-dark: #394b71;
$queenblue-darker: #324264;
$queenblue-darkest: #182030;

$palecerulean: #98c1d9;
$palecerulean-light: #b5d2e4;
$palecerulean-lighter: #d2e3ee;
$palecerulean-dark: #5096bf;
$palecerulean-darker: #3e82a9;
$palecerulean-darkest: #295670;

$independence: #4b3f72;
$independence-light: #6c5ba4;
$independence-lighter: #a59ac7;
$independence-lightest: #bbb3d5;
$independence-dark: #3a3159;
$independence-darker: #2a233f;
$independence-darkest: #191526;

$clean: #dfdce3;
$blackgrey: #131516;

/*
 * Text Heights
 * ========================================================================== */

$large-hero-font-size: 30px;
$large-header-font-size: 25px;
$normal-font-size: 14px;
$small-font-size: 12px;
$smallest-font-size: 11px;

/*
 * Media queries breakpoints
 * ========================================================================== */

$screen-xs-min: 480px; /* Extra small screen / phone */
$screen-sm-min: 768px; /* Small screen / tablet */
$screen-md-min: 992px; /* Medium screen / desktop */
$screen-lg-min: 1200px; /* Large screen / wide desktop */
