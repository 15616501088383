@import '../../../variables.scss';

.moon__st0 {
  fill: #989898;
}

.moon__st1 {
  fill: #e2e2e2;
}

.moon__st2 {
  fill: #bebebe;
}
